
<template>
  <div id="user-edit-profile">
    <h6 class="mb-3 text-uppercase bg-light p-2">
      Basic Info
    </h6>
    <div class="row gx-3 gy-3">
      <div class="col-md-6">
        <label class="form-label" for="firstName">First name</label>
        <input id="firstName" :readonly="true" type="text" class="form-control" v-model="data.first_name"
        :class="{ 'is-invalid': v$.data.first_name.$error }" placeholder="First name" name="first_name" />
        <div v-if="v$.data.first_name.$error"
          class="invalid-feedback">
          <span v-if="v$.data.first_name.required.$message">
            {{ v$.data.first_name.required.$message }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label" for="LastName">Last name</label>
        <input id="LastName" type="text" :readonly="true" class="form-control" v-model="data.last_name"
        :class="{ 'is-invalid': v$.data.last_name.$error }"  placeholder="Last Name" name="last_name" />
        <div v-if="v$.data.last_name.$error"
          class="invalid-feedback">
          <span v-if="v$.data.last_name.required.$message">
            {{ v$.data.last_name.required.$message }}</span>
        </div>
      </div>
      <div class="col-md-6">
        <label class="form-label" for="inputEmail">Email Address</label>
        <input id="inputEmail" :disabled="!data.is_verified" 
        :readonly="!data.is_verified"  label="Email" v-model="data.email"
        type="email" name="email" class="form-control"/>
      </div>
      <div class="col-md-6">
        <label class="form-label" for="inputMobile">Mobile</label>
        <input id="inputMobile" disabled :readonly="true"
          autocomplete="false" type="text" class="form-control" v-model="data.mobile"
        placeholder="mobile" name="mobile" />
      </div>
    </div>
    <h6 class="mb-3 mt-5 text-uppercase bg-light p-2">
      Address & Polling Unit
    </h6>
    <div class="row g-3">        
      <div class="col-12">
        <label for="address" class="form-label"> Address </label>
        <input :value="activeUser.address" id="address" name="address" 
          :readonly="true" class="form-control" type="text" placeholder="Address"> 
      </div>
      <div class="col-md-6">
        <label for="local_government_id" class="form-label">Local Government Area</label>
        <select name="local_government_id" id="local_government_id" class="form-select">
          <option disabled value="" >--choose LGA--</option>
          <option selected >{{activeUser.lga.name}}</option>
        </select>
      </div>
      <div class="col-md-6">
        <label for="registration_area_id" class="form-label">Ward</label>
        <select name="registration_area_id" id="registration_area_id"
         class="form-select" >
          <option disabled value="" >--choose ward--</option>
          <option selected >{{activeUser.ward.name}}</option>
        </select>
      </div>
      <div class="col-12">
        <label for="polling_unit_id" class="form-label">Polling Unit</label>
        <select name="polling_unit_id" id="polling_unit_id" class="form-select">
          <option disabled value="" >--choose ward--</option>
          <option selected >{{activeUser.unit.name}}</option>
        </select>
      </div>
    </div>
    <h6 class="mb-3 mt-5 text-uppercase bg-light p-2">
      Identity Info
    </h6>
    <div class="row g-3">        
      <div class="col-sm-6">
        <label class="form-label" for="nin_number">NIN number</label>
        <input :readonly="true" :value="activeUser.nin" id="nin_number" class="form-control" type="text"
          placeholder="NIN number" name="nin" >
      </div>
      <div class="col-sm-6">
        <label class="form-label" for="bvn_number">BVN number</label>
        <input :readonly="true" :value="activeUser.bvn" id="bvn_number" class="form-control" type="text"
          placeholder="BVN number" name="bvn" > 
      </div>
      <div class="col-12">
        <label class="form-label" for="vin_number">Voter Identification Number</label>
        <input :readonly="true" :value="activeUser.vin" id="vin_number" class="form-control" type="text"
          placeholder="VIN" name="vin"> 
      </div>
    </div>
    <h6 class="mb-3 mt-5 text-uppercase bg-light p-2">
      Bank Account
    </h6>
    <div class="row g-3 mb-5">        
      <div class="col-12">
        <label class="form-label" for="account_name">Account name</label>
        <input :readonly="true" id="account_name" type="text" class="form-control" 
        :value="activeUser.account_name"  placeholder="Account name" name="account_name"  /> 
      </div>
      <div class="col-sm-6">
        <label class="form-label" for="account_number">Account number</label>
        <input :readonly="true" id="account_number" type="text" class="form-control"
         :value="activeUser.account_number"
        placeholder="Account number" name="account_number"> 
      </div>
      <div class="col-sm-6">
        <label class="form-label" for="bank_id">Bank name</label>
        <select name="bank_id" id="bank_id" class="form-select" >
          <option disabled value="" >--select bank--</option>
          <option selected >{{activeUser.bank.name}}</option>
        </select>
      </div>
    </div>
    <!-- <div class="row mt-4 justify-content-end">
      <div class="col-12">
        <div class="text-end">
          <button type="button" @click.prevent="updateUser()" class="btn btn-primary mt-2">
            Save Changes
          </button>
        </div>
      </div>
    </div> -->
</div>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      data: {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
      }
    }
  },
  validations: {
    data:{
      first_name: {
        required: helpers.withMessage("First name is required", required),
      },
      last_name: {
        required: helpers.withMessage("Last name is required", required),
      },
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    updateUser() {
      this.v$.$validate().then(result =>{
        if (!result) return;
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/account/update',formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$store.commit('UPDATE_USER_INFO', response.data.data)
              this.resetForm()
            }
        })
      })
    },
    patchUser(){
      this.data = this.$filters.patchUpdatables(this.data, this.activeUser)
    }
  },
  created(){
    this.patchUser()
  },
}
</script>
